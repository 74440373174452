var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-main',{staticClass:"serviceList"},[_c('el-button',{staticStyle:{"margin-bottom":"10px"},attrs:{"type":"primary","size":"small"},on:{"click":function($event){_vm.addCustomFlag = true}}},[_vm._v("添加客服")]),_c('el-form',{staticClass:"el-form-search",attrs:{"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"客服昵称："}},[_c('el-input',{attrs:{"size":"small","placeholder":"请输入客服昵称/电话/姓名"},model:{value:(_vm.search.nickname),callback:function ($$v) {_vm.$set(_vm.search, "nickname", $$v)},expression:"search.nickname"}})],1),_c('el-form-item',{attrs:{"label":"店铺名称："}},[_c('el-select',{attrs:{"size":"small","placeholder":"请选择店铺"},model:{value:(_vm.search.store_id),callback:function ($$v) {_vm.$set(_vm.search, "store_id", $$v)},expression:"search.store_id"}},_vm._l((_vm.storeList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.store_name,"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"label":"提交时间："}},[_c('el-date-picker',{attrs:{"size":"small","type":"daterange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期"},model:{value:(_vm.search.time),callback:function ($$v) {_vm.$set(_vm.search, "time", $$v)},expression:"search.time"}})],1),_c('el-form-item',{attrs:{"label":" ","label-width":"25px"}},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.getServiceList(1)}}},[_vm._v("搜索")]),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":_vm.clearSearch}},[_vm._v("清空搜索条件")])],1)],1),_c('el-table',{attrs:{"data":_vm.serviceList,"height":"100%","header-cell-style":{ 'background-color': '#F8F9FA' }}},[_c('template',{slot:"empty"},[_c('No')],1),_c('el-table-column',{attrs:{"label":"店员信息","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"infoBox"},[_c('img',{attrs:{"src":row.avatar,"alt":""}}),_c('p',[_vm._v(_vm._s(row.nickname))])])]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"店员姓名","align":"center","width":"100px"}}),_c('el-table-column',{attrs:{"prop":"mobile","label":"手机号码","align":"center","width":"150px"}}),_c('el-table-column',{attrs:{"prop":"store_name","label":"店铺名称","align":"center","width":"250px"}}),_c('el-table-column',{attrs:{"label":"提交时间","align":"center","width":"250px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getDateformat(row.create_time))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.updateItem(row)}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.delItem(row)}}},[_vm._v("删除")]),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.goService(row)}}},[_vm._v("进入后台")])]}}])})],2),_c('Paging',{attrs:{"total":_vm.total,"page":_vm.page,"pageNum":_vm.row},on:{"updatePageNum":_vm.updateData}}),_c('el-dialog',{attrs:{"title":_vm.isUpdate ? '编辑客服' : '添加客服',"visible":_vm.addCustomFlag,"width":"52%"},on:{"update:visible":function($event){_vm.addCustomFlag=$event},"closed":_vm.dialogClose}},[_c('el-form',{ref:"addMan",staticClass:"formDia",attrs:{"label-position":_vm.labelPosition,"label-width":"120px","model":_vm.addMan,"rules":_vm.rules}},[_c('el-form-item',{attrs:{"label":"昵称：","prop":"nickname"}},[_c('el-input',{model:{value:(_vm.addMan.nickname),callback:function ($$v) {_vm.$set(_vm.addMan, "nickname", $$v)},expression:"addMan.nickname"}})],1),_c('el-form-item',{attrs:{"label":"头像","prop":"avatar"}},[_c('ReadyUploadSource',{attrs:{"showStyle":{
            width: '100px',
            height: '100px',
          },"path":_vm.addMan.avatar},on:{"getSource":_vm.getImg,"removeThis":_vm.removeImg}})],1),_c('el-form-item',{attrs:{"label":"客服名称：","prop":"name"}},[_c('el-input',{model:{value:(_vm.addMan.name),callback:function ($$v) {_vm.$set(_vm.addMan, "name", $$v)},expression:"addMan.name"}})],1),_c('el-form-item',{attrs:{"label":"所属门店："}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.addMan.store_id),callback:function ($$v) {_vm.$set(_vm.addMan, "store_id", $$v)},expression:"addMan.store_id"}},_vm._l((_vm.storeList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.store_name,"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"label":"手机号：","prop":"mobile"}},[_c('el-input',{model:{value:(_vm.addMan.mobile),callback:function ($$v) {_vm.$set(_vm.addMan, "mobile", $$v)},expression:"addMan.mobile"}})],1),_c('el-form-item',{attrs:{"label":"密码：","prop":!_vm.isUpdate ? 'password' : ''}},[_c('el-input',{attrs:{"type":"password","placeholder":!_vm.isUpdate ? '请输入密码' : '请输入新密码'},model:{value:(_vm.addMan.password),callback:function ($$v) {_vm.$set(_vm.addMan, "password", $$v)},expression:"addMan.password"}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.addCustomFlag = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.sureAdd}},[_vm._v("确 定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }